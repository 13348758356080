<template>
  <!-- 예약팝업 -->
  <div v-show="pp">
    <div class="basic_pp bottom_up" v-show="pp" :class="{ off: off }">
      <div class="dimm bg-body-i bg-opacity-50" @click="CloseReserve()"></div>
      <div class="content bg-body overflow-hidden">
        <div
          class="header position-sticky flex-between-center pb-3 text-truncate"
        >
          <h5>상품권 결제 요청하기</h5>

          <i class="p-2 fal fa-times" @click="CloseReserve()"></i>
        </div>
        <div class="body overflow-auto pt-1" ref="basic_pp_body">
          <div>
            <div class="bg-body">
              <div class="d-flex align-items-center position-relative">
                <div
                  class="bg-img rounded-4 w-px-90 h-px-90 flex-shrink-0 me-3"
                  :style="`background-image: url(${item.image_path})`"
                ></div>
                <div class="flex-grow-1 txt_box small w-80">
                  <div class="badges mb-2">
                    <ul class="small">
                      <li
                        class="badge bg-gray-relative-200 text-body rounded-1 me-1 fw-light"
                        v-for="(tag, tab_index) in item.tags"
                        :key="tab_index"
                      >
                        {{ tag }}
                      </li>
                    </ul>
                  </div>
                  <p class="h6 fw-bold mb-1">{{ item.name }}</p>
                  <p class="lh-sm text-gray-relative-600">
                    <i class="far fa-map-marker-alt me-1"></i>{{ item.address }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="cutting_line border-gray-relative-300 w-100 h-0 my-4"
          ></div>
          <div class="mt-2 mb-2">
            <div class="d-flex justify-content-between">
              <h6 class="mb-3 fw-bold">
                <i class="far fa-user-circle"></i> 본인정보
              </h6>
              <div>
                <span>{{ name }}</span
                ><span v-if="phone != ''">({{ phoneFormat(phone) }})</span>
              </div>
              <!-- <label class="text-gray-relative-600 small ms-2">성함</label>
                            <input type="text" class="form-control thick text-body border-gray-relative-300 bg-transparent fs-6 fw-bold mb-3" placeholder="예약자 성함" v-model="name">
            
                            <label class="text-gray-relative-600 small ms-2">연락처</label>
                            <input type="tel" class="form-control thick text-body border-gray-relative-300 bg-transparent fs-6 fw-bold" placeholder="예약자 연락처" v-model="phone"> -->
            </div>
          </div>
          <div class="cutting_line border-gray-relative-300 w-100 h-0"></div>
          <div class="my-4">
            <div class="mt-1 lh-sm px-1 mb-2">
              <h5>골프앤요트에서 알려드립니다!</h5>
            </div>
            <div class="mt-1 text-gray-relative-800 lh-sm px-1">
              ■ 상품권 결제 시 안내사항
            </div>
            <div class="mt-1 text-gray-relative-800 lh-sm px-1">
              <p class="ps-2">
                <small class="text-danger"
                  >- 골프장 예약은 회원이 직접하셔야 합니다.</small
                >
              </p>
              <p class="ps-2">
                <small
                  >- 상품권으로 결제 후, 골프장 마다 결제 대금지급 방식은 상이
                  할 수 있습니다.
                </small>
              </p>
              <p class="ps-2">
                <small
                  >- 상품권으로 결제할 금액은 골프장에 예약한 금액(그린피 할인,
                  회원권 할인 등 적용된 금액)으로 정확히 입력해서 결제를 요청해
                  주셔야 합니다.
                </small>
              </p>
            </div>
          </div>
          <div
            class="cutting_line border-gray-relative-300 w-100 h-0 my-4"
          ></div>
          <div>
            <div class="mb-3">
              <div class="mb-3 flex-between-center">
                <h6 class="fw-bold">
                  <i class="far fa-calendar-alt px-1"></i>티타임
                </h6>
                <span
                  class="fw-bold text-main bg-gray-relative-100 px-3 py-1 rounded-pill"
                  v-if="day != null"
                  >{{ DateFormmat(day) }}
                  <i
                    class="far fa-times ms-2 text-gray-relative-600 small"
                    @click="ResetDate()"
                  ></i>
                </span>
                <small class="text-gray-relative-600" v-else
                  >날짜를 선택해주세요</small
                >
              </div>
              <Calendar
                :min-date="MinDate()"
                :is-dark="this.$store.state.theme == 'dark'"
                :attributes="attributes"
                @dayclick="onDayClick"
                class="mb-3 border-gray-pp bg-body bg-opacity-25"
              />
              <div class="mt-1 text-gray-relative-800 lh-sm px-1">
                <small
                  >골프장 예약은 최소 2~3주전에 진행을 해주시면 예약하는데
                  원활하게 진행됩니다.</small
                >
              </div>
            </div>
            <div class="mb-3" v-if="reservation_type == true">
              <h6 class="mb-2 fw-bold"><i class="far fa-user-shield"></i> 예약자 또는 단체명</h6>
              <div class="radio_btn_box flex-between-center">
                <input
                  class="form-control"
                  type="text"
                  v-model="reservation_person"
                />
              </div>
            </div>
            <div class="mb-3" v-if="reservation_type == true">
              <h6 class="mb-2 fw-bold"><i class="far fa-map"></i> 예약 코스</h6>
              <div class="radio_btn_box flex-between-center">
                <input
                  class="form-control"
                  type="text"
                  v-model="reservation_info"
                />
              </div>
            </div>
            <div class="mb-3" v-if="reservation_type == true">
              <h6 class="mb-2 fw-bold">
                <i class="far fa-clock"></i> 예약 시간
              </h6>
              
              <input class="form-control time-input" type="time" v-model="reservation_confirm_hour_minute">
              <!-- <div class="radio_btn_box flex-between-center">
                <div class="form-check">
                  <select
                    class="form-control"
                    v-model="reservation_confirm_hour"
                  >
                    <option
                      v-for="(item, index) in hour_list"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                </div>
                <span class="px-1">:</span>
                <div class="form-check ms-1">
                  <select
                    class="form-control"
                    v-model="reservation_confirm_minute"
                  >
                    <option
                      v-for="(item, index) in minute_list"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                </div>
              </div> -->
            </div>
            <div class="mb-3" v-if="reservation_type == false">
              <h6 class="mb-2 fw-bold">
                <i class="far fa-clock"></i> 예약 시간
              </h6>
              <div class="radio_btn_box flex-between-center">
                <div class="form-check">
                  <select class="form-control" v-model="reserve_start_time">
                    <option
                      v-for="(item, index) in time_list"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                  <!-- <input class="form-control" type="time" id="radio1_1" v-model="reserve_start_time" step="00:30"> -->
                </div>
                <span class="px-1">~</span>
                <div class="form-check ms-1">
                  <select class="form-control" v-model="reserve_end_time">
                    <option
                      v-for="(item, index) in time_list"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                  <!-- <input class="form-control" type="time" id="radio1_2" v-model="reserve_end_time" step="00:30"> -->
                </div>
              </div>
            </div>
            <div class="mb-3">
              <h6 class="mb-2 fw-bold">
                <i class="far fa-user"></i> 예약 결제 인원
              </h6>
              <div class="radio_btn_box">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="radio1"
                    v-model="reserve_people"
                    value="1"
                  />
                  <label
                    class="form-control thick bg-body text-body border-gray-relative-300 fw-bold fs-6"
                    for="radio1"
                    >1명</label
                  >
                </div>
                <div class="form-check ms-1">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="radio2"
                    v-model="reserve_people"
                    value="2"
                  />
                  <label
                    class="form-control thick bg-body text-body border-gray-relative-300 fw-bold fs-6"
                    for="radio2"
                    >2명</label
                  >
                </div>
                <div class="form-check ms-1">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="radio3"
                    v-model="reserve_people"
                    value="3"
                  />
                  <label
                    class="form-control thick bg-body text-body border-gray-relative-300 fw-bold fs-6"
                    for="radio3"
                    >3명</label
                  >
                </div>
                <div class="form-check ms-1">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="radio4"
                    v-model="reserve_people"
                    value="4"
                  />
                  <label
                    class="form-control thick bg-body text-body border-gray-relative-300 fw-bold fs-6"
                    for="radio4"
                    >4명</label
                  >
                </div>
              </div>
              <div>
                <div class="mt-3 round_checkbox">
                  <input
                    type="checkbox"
                    v-model="proxy_check1"
                    id="check2"
                    @click="ResetProxyList()"
                  />
                  <label for="check2">
                    <div class="chk_icon me-2"></div>
                    <span class="conditions text-truncate">대리결제</span>
                  </label>
                </div>
                <small class="ms-4 text-gray-relative-600 d-flex">
                  <span>본인이 라운딩에 참여하지 않을 시, 체크해주세요.</span>
                </small>
                <div
                  class="bg-gray-relative-100 p-3 rounded"
                  v-if="proxy_check1"
                >
                  <ul v-for="(item, index) in proxy_list" :key="`P_${index}`">
                    <li class="d-flex mb-1">
                      <input
                        class="form-control me-2 w-40"
                        type="text"
                        placeholder="이름"
                        v-model="item.name"
                      />
                      <input
                        class="form-control w-60"
                        type="number"
                        placeholder="전화번호"
                        v-model="item.tel"
                      />
                      <i
                        class="far fa-plus-circle flex-center-center mx-2"
                        @click="AddProxyList()"
                      ></i>
                      <i
                        class="far fa-trash flex-center-center mx-2"
                        @click="RemoveProxyList(index)"
                      ></i>
                    </li>
                  </ul>
                  <small class="text-gray-relative-600">
                    1명 이상의 라운딩 참여자를 입력해주세요.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div
            class="cutting_line border-gray-relative-300 w-100 h-0 my-4"
          ></div>
          <div>
            <h6 class="mb-3 fw-bold">
              <i class="far fa-pen"></i> 기타 요청 사항
            </h6>
            <div>
              <div class="mt-3 round_checkbox">
                <input
                  type="checkbox"
                  v-model="cart_included_check"
                  id="check3"
                />
                <label for="check3">
                  <div class="chk_icon me-2"></div>
                  <span class="conditions text-truncate">카트비 포함</span>
                </label>
              </div>
              <small class="ms-4 text-gray-relative-600 d-flex">
                <span
                  >카트비를 포함하여 상품권 결제를 할 경우 체크해주세요.</span
                >
              </small>
            </div>
            <div>
              <div class="flex-between-center mt-3">
                <div class="round_checkbox">
                  <input type="checkbox" v-model="other_check" id="check4" />
                  <label for="check4">
                    <div class="chk_icon me-2"></div>
                    <span class="conditions text-truncate">기타 요청사항</span>
                  </label>
                </div>
                <small class="fs-px-12 lh-1 text-gray-relative-500">
                  ({{ other_requests.length }}/100)
                </small>
              </div> 
              <p class="small text-gray-600 lh-sm ms-4 mb-2">
                실제 골프장 결제금액과 회원앱 요청금액이 상이할 경우,결제금액을 모르실 경우 사유를 작성해주세요.
              </p>
              <textarea
                rows="2"
                maxlength="100"
                class="form-control"
                v-model="other_requests"
                :disabled="!other_check"
              ></textarea>
            </div>
          </div>
          <div
            class="cutting_line border-gray-relative-300 w-100 h-0 my-4"
          ></div>
          <div>
            <div class="mb-4" v-if="reservation_type == true">
              <h6 class="mb-3 fw-bold">
                <i class="far fa-coin"></i> 결제 요청 금액
              </h6>
              <div class="fw-600 lh-sm flex-shrink-0">
                <div class="d-flex">
                  <input
                    type="text"
                    v-model="reservation_price"
                    class="form-control"
                    @input="inputOnlyNumberFormat()"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="cutting_line border-gray-relative-300 w-100 h-0 my-2"
          ></div>
          <div>
            <div>
              <div class="round_checkbox py-3">
                <div class="chk_icon me-2"></div>
                <small class="conditions"
                  >본인은
                  <span
                    class="text-primary text-decoration-underline"
                    @click="TermPopupOpen()"
                    >개인 정보수집 및 이용, 개인정보 제3자 제공</span
                  >내용을 확인하였으며, 결제 진행에 동의합니다</small
                >
                <!-- <small class="text-decoration-underline ms-3 text-gray-relative-600" >보기</small> -->
              </div>
              <div
                class="btn thick btn-main text-white w-100"
                :class="CheckReservationDisable()"
                @click="Reserve()"
              >
                동의하고 결제요청하기
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="alert-container position-fixed top-0 start-0"
      v-show="confirm_pp1"
    >
      <div
        class="dimm w-100 h-100 bg-body-i bg-opacity-50 position-absolute top-0 start-0 zindex-100"
      ></div>
      <div
        class="contents w-90 position-absolute top-50 start-50 translate-middle bg-body bg-opacity-85 backdrop-blur-10 rounded-4 text-center zindex-100"
      >
        <div class="inner p-3">
          <div>
            <h5 class="mb-3">상품권 결제요청 내역 확인</h5>
            <div class="p-3 mb-2 bg-gray-relative-100 rounded">
              <p class="text-gray-relative-600">라운드 일자</p>
              <b
                >{{ DateFormmat(day) }} {{ reservation_confirm_hour }}:{{
                  reservation_confirm_minute
                }}</b
              >
            </div>
            <div class="p-3 bg-gray-relative-100 rounded">
              <div class="mb-2 flex-between-center">
                <small class="text-gray-relative-600 flex-shrink-0 me-2"
                  >예약자명</small
                >
                <span>{{ reservation_person }}</span>
              </div>
              <div class="mb-2 flex-between-center">
                <small class="text-gray-relative-600 flex-shrink-0 me-2"
                  >예약자 연락처</small
                >
                <span>{{ phoneFormat(phone) }}</span>
              </div>
              <div class="mb-2 flex-between-center">
                <small class="text-gray-relative-600 flex-shrink-0 me-2"
                  >티타임/코스</small
                >
                <span class="text-end"
                  >{{ item.name }}/{{ reservation_confirm_hour }}:{{
                    reservation_confirm_minute
                  }}/{{ reservation_info }}</span
                >
              </div>
              <div class="mb-2 flex-between-start">
                <small class="text-gray-relative-600 flex-shrink-0 me-2"
                  >조건</small
                >
                <div class="lh-sm">
                  <span>그린피</span>
                  <span v-if="cart_included_check">/카트비</span>
                </div>
              </div>
              <div class="mb-2 flex-between-start" v-if="other_check">
                <small class="text-gray-relative-600 flex-shrink-0 me-2"
                  >기타요청</small
                >
                <div
                  class="lh-sm"
                  v-if="other_requests != ''"
                  style="text-align: left"
                >
                  {{ other_requests }}
                </div>
              </div>
            </div>
            <div class="lh-1 py-3 text-start">
              <small class="text-danger"
                >라운드 일자와 입력한 예약자 정보가 실제와 다른 경우</small
              ><br />
              <small>라운드 안내 및 결제가 불가할 수 있습니다.</small>
            </div>
          </div>
        </div>
        <div class="hr"></div>
        <div class="border-top border-gray-relative-500 flex-center-center">
          <button
            class="btn p-2 py-3 flex-grow-1 text-body"
            @click="confirm_pp1 = false"
          >
            취소
          </button>
          <span class="vr bg-gray-relative-600"></span>
          <button
            class="btn p-2 py-3 flex-grow-1 text-main"
            @click="Reserve2()"
          >
            확인 후 결제요청
          </button>
        </div>
      </div>
    </div>

    <TermsPopup ref="TermsPopup" />
  </div>
</template>

<script>
import TermsPopup from '@/components/popup/ReservationTerm.vue';
import { Calendar, DatePicker } from 'v-calendar';
// import Calendar from 'v-calendar/lib/components/calendar.umd';
// import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { format } from '@/mixins';
const CryptoJS = require('crypto-js');
var moment = require('moment'); // require

export default {
  components: {
    Calendar,
    DatePicker,
    TermsPopup,
  },
  mixins: [format],
  data() {
    return {
      name: this.$store.state.name,
      phone: this.$store.state.phone,
      item: {},
      reserve_people: '',
      reserve_start_time: '',
      reserve_end_time: '',
      day: null,
      attributes: [],
      date: '',
      reservation_type: true,
      proxy_check1: false,
      proxy_name1: '',
      proxy_name2: '',
      proxy_name3: '',
      proxy_name4: '',
      proxy_contact1: null,
      proxy_contact2: null,
      proxy_contact3: null,
      proxy_contact4: null,
      cart_included_check: false,
      other_check: false,
      other_requests: '',

      confirm_pp1: false,

      // front ui
      pp: false,
      off: false,
      timeout_id: null,
      time_list: [
        { text: '05:00', value: '05:00' },
        { text: '06:00', value: '06:00' },
        { text: '07:00', value: '07:00' },
        { text: '08:00', value: '08:00' },
        { text: '09:00', value: '09:00' },
        { text: '10:00', value: '10:00' },
        { text: '11:00', value: '11:00' },
        { text: '12:00', value: '12:00' },
        { text: '13:00', value: '13:00' },
        { text: '14:00', value: '14:00' },
        { text: '15:00', value: '15:00' },
        { text: '16:00', value: '16:00' },
        { text: '17:00', value: '17:00' },
        { text: '18:00', value: '18:00' },
        { text: '19:00', value: '19:00' },
        { text: '20:00', value: '20:00' },
        { text: '21:00', value: '21:00' },
        { text: '22:00', value: '21:00' },
      ],
      hour_list: [
        { text: '05', value: '05' },
        { text: '06', value: '06' },
        { text: '07', value: '07' },
        { text: '08', value: '08' },
        { text: '09', value: '09' },
        { text: '10', value: '10' },
        { text: '11', value: '11' },
        { text: '12', value: '12' },
        { text: '13', value: '13' },
        { text: '14', value: '14' },
        { text: '15', value: '15' },
        { text: '16', value: '16' },
        { text: '17', value: '17' },
        { text: '18', value: '18' },
        { text: '19', value: '19' },
        { text: '20', value: '20' },
        { text: '21', value: '21' },
        { text: '22', value: '21' },
      ],
      minute_list: [],
      reservation_confirm_hour_minute: '',
      reservation_confirm_hour: '',
      reservation_confirm_minute: '',
      reservation_person: '',
      reservation_info: '',
      reservation_price: '',
      proxy_list: [],
    };
  },
  watch: {
    reservation_confirm_hour_minute(newTime) {
      if (newTime) {
        const [hour, minute] = newTime.split(":");
        this.reservation_confirm_hour = hour;
        this.reservation_confirm_minute = minute;
      }
    },
    pp: function () {
      if (this.pp == false) {
        this.ResetDate();
      }
    },
  },
  methods: {
    AddProxyList() {
      this.proxy_list.push({ name: '', tel: '' });
    },
    RemoveProxyList(index) {
      if (this.proxy_list.length > 1) {
        this.proxy_list.splice(index, 1);
      }
    },
    ResetProxyList() {
      this.proxy_list = [{ name: '', tel: '' }];
    },
    CheckReservationDisable() {
      let check_disable = false;

      if (check_disable == false) {
        if (this.day != null) {
          check_disable = false;
        } else {
          check_disable = true;
        }
      }

      if (check_disable == false) {
        if (this.reserve_people != '') {
          check_disable = false;
        } else {
          check_disable = true;
        }
      }

      if (check_disable == false) {
        if (this.reservation_confirm_hour != '') {
          check_disable = false;
        } else {
          check_disable = true;
        }
      }

      if (check_disable == false) {
        if (this.reservation_confirm_minute != '') {
          check_disable = false;
        } else {
          check_disable = true;
        }
      }

      if (check_disable == false) {
        if (this.reservation_info != '') {
          check_disable = false;
        } else {
          check_disable = true;
        }
      }

      if (check_disable == false) {
        if (this.reservation_price != '') {
          check_disable = false;
        } else {
          check_disable = true;
        }
      }

      if (check_disable) {
        return 'disabled';
      } else {
        return '';
      }
    },
    TermPopupOpen() {
      // this.terms_check1 = false

      this.$refs.TermsPopup.pp_on(this.item.name);
    },
    comma(str) {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, '');
    },
    inputOnlyNumberFormat() {
      const price = this.reservation_price;
      this.reservation_price = this.comma(this.onlynumber(this.uncomma(price)));
    },

    onlynumber(str) {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1');
    },
    CheckReservationType() {
      this.reservation_confirm_hour = '';
      this.reservation_confirm_minute = '';
      this.reservation_person = '';
      this.reservation_info = '';
      this.reserve_start_time = '';
      this.reserve_end_time = '';
      this.reservation_price = '';
      this.proxy_check1 = false;
      this.cart_included_check = false;
      this.other_check = '';
      this.other_requests = '';
      this.proxy_list = [];
    },
    CheckForm() {
      let confirm = true;

      if (this.day == null) {
        this.$alert('예약 날짜를 선택해주세요.');
        confirm = false;
        return false;
      }

      if (this.reserve_people == '') {
        this.$alert('예약 인원을 선택해주세요.');
        confirm = false;
        return false;
      }

      if (this.reservation_type == true) {
        if (this.reservation_confirm_hour == '') {
          this.$alert('예약시간을 선택해주세요.');
          confirm = false;
          return false;
        }

        if (this.reservation_confirm_minute == '') {
          this.$alert('예약시간을 선택해주세요.');
          confirm = false;
          return false;
        }

        if (this.reservation_info == '') {
          this.$alert('예약코스를 입력해주세요.');
          confirm = false;
          return false;
        }

        if (this.reservation_price == '') {
          this.$alert('예약금액을 입력해주세요.');
          confirm = false;
          return false;
        }
      } else {
        if (this.reserve_start_time == '') {
          this.$alert('예약시간을 선택해주세요.');
          confirm = false;
          return false;
        }
      }

      let proxy_check = this.proxy_check1;
      let proxy_able = true;
      if (proxy_check) {
        for (const el of this.proxy_list) {
          const name = el.name;
          const tel = el.tel;

          if (name == '') {
            proxy_able = false;
          }
          if (tel == '') {
            proxy_able = false;
          }
        }
      }

      if (proxy_check) {
        if (proxy_able == false) {
          this.$alert('대리결제시 1명 이상의 라운딩 참여자를 입력해주세요.');
          confirm = false;
          return false;
        }
      }

      if(this.reservation_price ==0){
        this.$alert('결제 금액을 확인해주세요.');
        confirm = false;
        return false;
      }

      if(this.reservation_price ==''){
        this.$alert('결제 금액을 확인해주세요.');
        confirm = false;
        return false;
      }
      // return false;
      return confirm;
    },
    Reserve() {
      if (this.CheckForm()) {
        this.confirm_pp1 = true;

        // const code = this.item.code;
        // const start_time = this.reserve_start_time;
        // const end_time = this.reserve_end_time;
        // const people = this.reserve_people;
        // const date = this.date;
        // const reservation_type = this.reservation_type;
        // const reservation_confirm_hour = this.reservation_confirm_hour;
        // const reservation_confirm_minute = this.reservation_confirm_minute;
        // const reservation_info = this.reservation_info;
        // const reservation_price = this.uncomma(this.reservation_price);

        // const confirm_time = reservation_confirm_hour+":"+reservation_confirm_minute;
        // const body = {start_time,end_time,people,date,code,reservation_type,confirm_time,reservation_info,reservation_price};

        // const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

        // this.$confirm(`예약하시겠습니까?`).then((result)=>{
        //     if( result.is_ok ){
        //         this.loading = true;

        //         this.$http.post('/front/reservation/AddReservation',{req}).then(
        //             (res) =>  {
        //                 if(res.status == 200){
        //                     this.loading = false;
        //                     if(res.data.code =="200"){

        //                         this.$alert('예약을 요청했습니다.<br>매장 측에서 확인후 결제하시면 됩니다.').then(()=>{this.$router.push('/mypage/reservation')});
        //                     }
        //                 }
        //             }
        //         )

        //     }else if( result.is_cancel ){
        //         this.$alert('취소되었습니다.');
        //     }
        // })
      }
    },
    Reserve2() {
      const code = this.item.code;
      const start_time = this.reserve_start_time;

      const end_time = this.reserve_end_time;
      const people = this.reserve_people;
      const date = this.date;
      const reservation_type = this.reservation_type;
      const reservation_confirm_hour = this.reservation_confirm_hour;
      const reservation_confirm_minute = this.reservation_confirm_minute;
      const reservation_info = this.reservation_info;
      const reservation_price = this.uncomma(this.reservation_price);
      const proxy_check = this.proxy_check1;
      const proxy_list = this.proxy_list;
      const cart_check = this.cart_included_check;
      const other_check = this.other_check;

      const other_requests = this.other_requests;
      const reservation_person  = this.reservation_person ;
      const confirm_time =
        reservation_confirm_hour + ':' + reservation_confirm_minute;
      const body = {
        start_time,
        end_time,
        people,
        date,
        code,
        reservation_type,
        confirm_time,
        reservation_person,
        reservation_info,
        reservation_price,
        proxy_check,
        proxy_list,
        cart_check,
        other_check,
        other_requests,
      };

      console.log(body);

      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();
      this.loading = true;
      this.$http
        .post('/front/reservation/AddReservation', { req })
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            if (res.data.code == '200') {
              const r_code = res.data.r_code;
              // this.$alert('예약을 요청했습니다.<br>매장 측에서 확인후 결제하시면 됩니다.').then(()=>{this.$router.push('/mypage/reservation')});
              this.confirm_pp1 = false;
              this.$router.push(`/mypage/request-complete/${r_code}`);
            } else if (res.data.code == '100') {
              this.$alert(
                '결제하실 금액보다 잔여 금액이 부족합니다. 확인 후 결제 부탁드립니다.'
              );
            } else if (res.data.code == '9999') {
            }
          }
        });
    },
    CloseReserve() {
      this.$confirm('예약을 취소하시겠습니까?').then((result) => {
        if (result.is_ok) {
          this.pp_off();
        }
      });
    },

    // 캘린더 관련==================================
    onDayClick(day) {
      if (!day.isDisabled) {
        this.day = day.date;
        this.date = day.id;
        if (this.attributes.length > 1) {
          const popResult = this.attributes.pop();
        }
        this.attributes.push({
          key: 'selected_day',
          highlight: 'green',
          dates: this.day,
        });
      }
    },
    // MaxDate(){
    // 	let today = new Date();
    // 	return new Date(today.setMonth(today.getMonth() + 1))
    // },
    MinDate() {
      let today = new Date();
      const new_day = today.setDate(today.getDate() + 7);

      return new_day;
    },
    ResetDate() {
      this.day = null;
      this.attributes = [
        {
          key: 'today',
          dot: true,
          dates: new Date(),
        },
      ];
      this.reserve_start_time = '';
      this.reserve_end_time = '';
      this.reserve_people = '';

      // this.terms_check1 = false;
    },
    DateFormmat(date_str) {
      let date = new Date(date_str);
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let getDay = `(${moment(date).format('dddd')[0]})`;

      return month + '월 ' + day + '일' + getDay;
    },
    TimeFormmat(time_str) {
      if (time_str == 1) {
        return '오전';
      } else {
        return '오후';
      }
    },
    // 캘린더 관련 end ==============================

    // front ui
    pp_off() {
      this.off = true;
      this.reservation_type = true;
      this.reservation_price = '';
      this.CheckReservationType();
      if (this.timeout_id) {
        clearTimeout(this.timeout_id);
      }

      this.timeout_id = setTimeout(() => {
        this.pp = false;
        this.off = false;
        if (this.$refs.basic_pp_body) {
          this.$refs.basic_pp_body.scrollTop = 0;
        }
        this.timeout_id = null;
      }, 200);
    },
    SetMinute() {
      let minute = [];
      for (var i = 0; i <= 60; i++) {
        if (i < 10) {
          const value = '0' + i;
          minute.push({ text: value, value });
        } else {
          minute.push({ text: i, value: i });
        }
      }

      this.minute_list = minute;
    },
  },

  mounted() {
    this.SetMinute();
    this.ResetDate();
  },
};
</script>

<style lang="scss" scoped>
.cutting_line {
  border-top: 1px dashed;
}

.vc-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: block;
  background-color: transparent;
}

.alert-container {
  width: 100vw;
  height: 100vh;
  z-index: 999;

  .dimm {
    animation: fadeIn 0.2s;
  }
  .contents {
    min-width: 270px;
    max-width: 500px;
    transform-origin: 0 0;
    animation: zoomin 0.2s;
    animation-fill-mode: forwards;

    .border-top {
      border-width: 0.5px !important;
    }
  }
}
</style>
